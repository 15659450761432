import React, { useState } from 'react';
import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import './style.sass';
import { areas } from '../../constants/address';

export default () => {
  const [targetArea, setTargetArea] = useState();

  const data = useStaticQuery(graphql`
    query map {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "shrine-post" } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 400)
            id
            fields {
              slug
            }
            frontmatter {
              title
              area
            }
          }
        }
      }
    }
  `);

  const postedArea = [];
  data.allMarkdownRemark.edges.forEach(item => {
    postedArea.push(item.node.frontmatter.area);
  });

  const handleTap = e => {
    if (targetArea) {
      if (!e.currentTarget.classList.contains('path--disabled')) {
        navigate(`/shrine-area/${e.currentTarget.id}`);
      }
    } else {
      setTargetArea(e.currentTarget.getAttribute('area'));
    }
  };

  const handleLabelTap = id => {
    if (id) {
      navigate(`/shrine-area/${id}`);
    }
  };

  const handleReturnButton = e => {
    setTargetArea(null);
  };

  return (
    <div className={`map ${!targetArea ? '' : `map--area map--${targetArea}`}`}>
      <div className="map__inner">
        <div className="map__content">
          <svg
            className="map__image"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 817.37 874.21"
          >
            <g id="line">
              <polyline
                id="line"
                fill="none"
                stroke="#6D5B3A"
                points="0,781.59 92.62,781.59 92.62,874.21"
              />
            </g>

            {areas.map(area => {
              return (
                <g key={`g_${area.id}`} id={area.id}>
                  {area.prefectures.map((prefecture, i) => {
                    const enabled = postedArea.includes(prefecture.id);

                    if (!!prefecture.d) {
                      return (
                        <g key={`p_${i}`}>
                          <path
                            id={prefecture.id}
                            className={`path ${prefecture.id} ${
                              enabled ? '' : 'path--disabled'
                            }`}
                            area={area.id}
                            onClick={handleTap}
                            d={prefecture.d}
                          />
                          <text
                            className={`map__areaLabel ${
                              enabled ? '' : 'map__areaLabel--disabled'
                            }`}
                            onClick={() => handleLabelTap(prefecture.id)}
                            x={prefecture.x}
                            y={prefecture.y}
                            style={{ fontSize: `${prefecture.fontSize}rem` }}
                            fill="black"
                          >
                            {prefecture.name}
                          </text>
                        </g>
                      );
                    } else {
                      return (
                        <g key={`g_${area.id}`}>
                          <polygon
                            id={prefecture.id}
                            className={`path ${prefecture.id} ${
                              enabled ? '' : 'path--disabled'
                            }`}
                            area={area.id}
                            onClick={handleTap}
                            points={prefecture.points}
                          />
                          <text
                            className={`map__areaLabel ${
                              enabled ? '' : 'map__areaLabel--disabled'
                            }`}
                            onClick={() =>
                              handleLabelTap(enabled ? prefecture.id : null)
                            }
                            x={prefecture.x}
                            y={prefecture.y}
                            style={{ fontSize: `${prefecture.fontSize}rem` }}
                            fill="black"
                          >
                            {prefecture.name}
                          </text>
                        </g>
                      );
                    }
                  })}
                </g>
              );
            })}
          </svg>

          {areas.map((area, i) => {
            return (
              <div
                key={`buttons${i}`}
                className={`map__buttons map__buttons--${area.id} ${
                  area.id === targetArea ? 'map__buttons--active' : ''
                }`}
              >
                {area.prefectures.map((prefecture, j) => {
                  return (
                    <div
                      key={`button${j}`}
                      className={`map__buttonContainer map__buttonContainer--${prefecture.id}`}
                    >
                      <Link
                        className={`map__button map__button--${area.id}`}
                        to={`/areas/${prefecture.id}`}
                      >
                        {prefecture.name}
                      </Link>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {targetArea ? (
          <div className="map__returnButton" onClick={handleReturnButton}>
            全国
          </div>
        ) : null}
      </div>
    </div>
  );
};
