import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { useStaticQuery, Link } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import './style.sass';

const settings = {
  className: 'center',
  centerMode: true,
  centerPadding: '165px',
  slidesToShow: 1,
  dots: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '40px',
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 1,
      },
    },
  ],
};

export default props => {
  const [isStart, setIsStart] = useState(false);
  useEffect(() => {
    setIsStart(true);
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      ) {
        edges {
          node {
            excerpt(pruneLength: 400)
            id
            fields {
              slug
            }
            frontmatter {
              date
              title
              name
              area
              tags
              blessings
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 120, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const edges = data.allMarkdownRemark.edges;

  let items = [];
  edges.forEach(item => {
    if (item.node.frontmatter.title === props.ca1) {
      items.push(item);
    }
    if (item.node.frontmatter.title === props.ca2) {
      items.push(item);
    }
    if (item.node.frontmatter.title === props.ca3) {
      items.push(item);
    }
  });

  // console.log(items[0].node.frontmatter.featuredimage.childImageSharp.fluid);

  return isStart ? (
    <div className="slide">
      <Slider {...settings}>
        <div>
          <div className="slide__box">
            <div>
              <Link className="slide__item" to={items[0].node.fields.slug}>
                {/* <PreviewCompatibleImage
                  imageInfo={{
                    image: items[0].node.frontmatter.featuredimage,
                    alt: `${items[0].node.frontmatter.title}`,
                  }}
                /> */}
                <img
                  className="slide__thumb"
                  src={
                    items[0].node.frontmatter.featuredimage.childImageSharp
                      .fluid.src
                  }
                  srcSet={
                    items[0].node.frontmatter.featuredimage.childImageSharp
                      .fluid.srcSet
                  }
                  alt={items[0].node.frontmatter.title}
                />
                <div className="slide__title">
                  {items[0].node.frontmatter.title}
                </div>
                <figure className="slide__figure" />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="slide__box">
            <div>
              <Link className="slide__item" to={items[1].node.fields.slug}>
                {/* <PreviewCompatibleImage
                  imageInfo={{
                    image: items[1].node.frontmatter.featuredimage,
                    alt: `${items[1].node.frontmatter.title}`,
                  }}
                /> */}
                <img
                  className="slide__thumb"
                  src={
                    items[1].node.frontmatter.featuredimage.childImageSharp
                      .fluid.src
                  }
                  srcSet={
                    items[1].node.frontmatter.featuredimage.childImageSharp
                      .fluid.srcSet
                  }
                  alt={items[1].node.frontmatter.title}
                />
                <div className="slide__title">
                  {items[1].node.frontmatter.title}
                </div>
                <figure className="slide__figure" />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <div className="slide__box">
            <div>
              <Link className="slide__item" to={items[2].node.fields.slug}>
                {/* <PreviewCompatibleImage
                  imageInfo={{
                    image: items[2].node.frontmatter.featuredimage,
                    alt: `${items[2].node.frontmatter.title}`,
                  }}
                /> */}
                <img
                  className="slide__thumb"
                  src={
                    items[2].node.frontmatter.featuredimage.childImageSharp
                      .fluid.src
                  }
                  srcSet={
                    items[2].node.frontmatter.featuredimage.childImageSharp
                      .fluid.srcSet
                  }
                  alt={items[2].node.frontmatter.title}
                />
                <div className="slide__title">
                  {items[2].node.frontmatter.title}
                </div>
                <figure className="slide__figure" />
              </Link>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  ) : null;
};
