import React from 'react';
import { Helmet } from 'react-helmet-async';
import constants from '../constants';
export default ({ title, description, href }) => {
  return (
    <Helmet titleTemplate={`%s | ${constants.siteName}`}>
      <html lang="ja" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />
      <meta
        property="og:image"
        content={`https://${constants.host.production}/img_normal.png`}
      />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content={`【無料夢占い】${description}`}
      />
      <meta property="og:url" content={href} />
      <meta property="og:site_name" content={constants.siteName} />
      {/* <meta property="fb:app_id" content={facebookAppId} /> */}
      {/* <meta name="twitter:card" content="summary" /> */}
      {/* <meta name="twitter:site" content={`@${blogAuthorTwitterUserName}`} /> */}
    </Helmet>
  );
};
