import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import SubTitle, { SubTitleTypes } from '../components/SubTitle';
import Map from '../components/Map';
import Carousel from '../components/Carousel';
import BlogList from '../components/BlogList';
import constants from '../constants';

export const IndexPageTemplate = ({ ca1, ca2, ca3 }) => {
  return (
    <div className="index">
      <Carousel ca1={ca1} ca2={ca2} ca3={ca3} />

      <div id="map">
        <SubTitle type={SubTitleTypes.map} />
        <Map />
      </div>

      <SubTitle type={SubTitleTypes.article} />
      <BlogList type={constants.articleTypes.new} value={''} />
    </div>
  );
};

const IndexPage = ({ location, data }) => {
  const { ca1, ca2, ca3 } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Seo title="ヒナタビ | Topページ" description="" href={location.href} />

      <IndexPageTemplate ca1={ca1} ca2={ca2} ca3={ca3} />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        ca1
        ca2
        ca3
      }
    }
  }
`;
